import {
	ContentFeedback,
	DisplayFeedback,
	DropFeedback,
	ListFeedback,
	ModalFeedback,
	PageFeedback,
	PillFeedback,
	PreviewFeedback,
	SearchFeedback,
	SectionFeedback,
	SelectFeedback,
	SlideFeedback,
	TableFeedback,
} from 'molecules/feedbacks';

interface Feedback {
	type: string;
	icon: any;
	title?: string;
	text?: string;
	actions?: string;
	children?: any;
	tailwind?: any;
}

const Feedback = ({ type, icon, title, text, actions, children, tailwind }: Feedback) => {
	const props = { icon, title, text, tailwind, actions, children };
	switch (type) {
		case 'display':
		case 'grid':
			return <DisplayFeedback {...props} />;

		case 'content':
			return <ContentFeedback {...props} />;

		case 'drop':
			return <DropFeedback {...props} />;

		case 'action':
		case 'list':
		case 'profile':
		case 'rating':
		case 'upvote':
			return <ListFeedback {...props} />;

		case 'modal':
			return <ModalFeedback {...props} />;

		case 'page':
			return <PageFeedback {...props} />;

		case 'pill':
			return <PillFeedback {...props} />;

		case 'preview':
		case 'preview-action':
			return <PreviewFeedback {...props} />;

		case 'search':
		case 'search-model':
		case 'search-recent':
		case 'search-trending':
		case 'search-query':
			return <SearchFeedback {...props} />;

		case 'section':
			return <SectionFeedback {...props} />;

		case 'select':
			return <SelectFeedback {...props} />;

		case 'slide':
			return <SlideFeedback {...props} />;

		case 'table':
			return <TableFeedback {...props} />;

		default:
			return <DisplayFeedback {...props} />;
	}
};

export { Feedback };
