import {
	Feedback,
	FeedbackActions,
	FeedbackBody,
	FeedbackHeader,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const ModalFeedback = ({ icon, title, text, children, tailwind }) => {
	// Render
	return (
		<Feedback align='text-left' spacing='p-4' {...tailwind?.feedback}>
			<FeedbackHeader>
				<FeedbackIcon
					icon={typeof icon !== 'object' && icon}
					{...icon}
					color='green-400'
					fontSize='text-5xl'
					{...tailwind?.icon}
				/>
			</FeedbackHeader>
			<FeedbackBody>
				<FeedbackTitle>{title}</FeedbackTitle>
				<FeedbackText>{text}</FeedbackText>
			</FeedbackBody>
			{children && <FeedbackActions>{children}</FeedbackActions>}
		</Feedback>
	);
};

export { ModalFeedback };
