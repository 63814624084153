import { Fragment } from 'react';

import {
	Feedback,
	FeedbackActions,
	FeedbackBody,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const ContentFeedback = ({ icon, title, text, actions, children, tailwind }) => {
	// Render
	return (
		<Fragment>
			<Feedback align='text-center' spacing='p-4 m-4' {...tailwind?.feedback}>
				<FeedbackBody>
					<FeedbackIcon icon={typeof icon !== 'object' && icon} {...icon} {...tailwind?.icon} />
					<FeedbackTitle>{title}</FeedbackTitle>
					<FeedbackText>{text}</FeedbackText>
					{actions && <FeedbackActions display='flex-middle'>{actions}</FeedbackActions>}
				</FeedbackBody>
			</Feedback>
			{children}
		</Fragment>
	);
};

export { ContentFeedback };
