import { Feedback, FeedbackBody, FeedbackIcon, FeedbackText, FeedbackTitle } from '@playbooks/interface/feedbacks';

const DisplayFeedback = ({ icon, title, text, tailwind }) => {
	// Render
	return (
		<Feedback align='text-center' spacing='p-8' {...tailwind?.feedback}>
			<FeedbackBody>
				<FeedbackIcon icon={typeof icon !== 'object' && icon} {...icon} {...tailwind?.icon} />
				<FeedbackTitle>{title}</FeedbackTitle>
				<FeedbackText>{text}</FeedbackText>
			</FeedbackBody>
		</Feedback>
	);
};

export { DisplayFeedback };
