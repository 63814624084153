import {
	Feedback,
	FeedbackActions,
	FeedbackBody,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const SearchFeedback = ({ icon, title, text, children, tailwind }) => {
	// Render
	return (
		<Feedback align='text-center' spacing='p-4' {...tailwind?.feedback}>
			<FeedbackBody>
				<FeedbackIcon icon={typeof icon !== 'object' && icon} {...icon} {...tailwind?.icon} />
				<FeedbackTitle>{title}</FeedbackTitle>
				<FeedbackText>{text}</FeedbackText>
				{children && <FeedbackActions display='flex-middle'>{children}</FeedbackActions>}
			</FeedbackBody>
		</Feedback>
	);
};

export { SearchFeedback };
