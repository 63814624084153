import { Fragment } from 'react';

import {
	Feedback,
	FeedbackActions,
	FeedbackBody,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const DropFeedback = ({ icon, title, text, children, tailwind }) => {
	// Render
	return (
		<Fragment>
			<Feedback align='text-center' spacing='p-4' {...tailwind?.feedback}>
				<FeedbackBody>
					<FeedbackIcon icon={typeof icon !== 'object' && icon} {...icon} {...tailwind?.icon} />
					<FeedbackTitle size='p'>{title}</FeedbackTitle>
					<FeedbackText fontSize='text-sm'>{text}</FeedbackText>
					{children && (
						<FeedbackActions display='flex-middle' spacing='pt-4'>
							{children}
						</FeedbackActions>
					)}
				</FeedbackBody>
			</Feedback>
		</Fragment>
	);
};

export { DropFeedback };
