import { Fragment } from 'react';

import {
	Feedback,
	FeedbackActions,
	FeedbackBody,
	FeedbackIcon,
	FeedbackText,
	FeedbackTitle,
} from '@playbooks/interface/feedbacks';

const PreviewFeedback = ({ icon, title, text, children, tailwind }) => {
	// Render
	return (
		<Fragment>
			<Feedback align='text-center' spacing='p-4 my-4' {...tailwind?.feedback}>
				<FeedbackBody>
					<FeedbackIcon icon={typeof icon !== 'object' && icon} {...icon} {...tailwind?.icon} />
					<FeedbackTitle>{title}</FeedbackTitle>
					<FeedbackText fontSize='text-sm'>{text}</FeedbackText>
				</FeedbackBody>
				{children && <FeedbackActions display='flex-middle'>{children}</FeedbackActions>}
			</Feedback>
		</Fragment>
	);
};

export { PreviewFeedback };
