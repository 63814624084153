import { Feedback, FeedbackBody, FeedbackIcon, FeedbackText, FeedbackTitle } from '@playbooks/interface/feedbacks';

const SelectFeedback = ({ icon, title, text, tailwind }) => {
	// Render
	return (
		<Feedback align='text-center' spacing='p-4' {...tailwind?.feedback}>
			<FeedbackBody>
				<FeedbackIcon icon={typeof icon !== 'object' && icon} {...icon} fontSize='text-3xl' {...tailwind?.icon} />
				<FeedbackTitle>{title}</FeedbackTitle>
				<FeedbackText fontSize='text-sm'>{text}</FeedbackText>
			</FeedbackBody>
		</Feedback>
	);
};

export { SelectFeedback };
